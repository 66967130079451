import React from "react";

// react-bootstrap components
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Modal,
  Form,
  Image,
  FormGroup,
  InputGroup,
  OverlayTrigger,
  Tooltip,
  Tab,
  Nav,
} from "react-bootstrap";
import Select from "react-select";
// core components
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ReactDatetime from "react-datetime";
import moment from "moment";
import moments from "moment-timezone";
import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import {
  getProduk,
  tambahProduk,
  importProduk,
  editProduk,
  getProdukUpgrade,
  getProdukPo,
  getProdukUpgradePo,
  getTotalProdukPo,
  tambahPo,
  exportAllProduk,
  deleteProduk,
} from "stores";
import "./Pagination.css";
import CurrencyInput from "react-currency-input-field";

function Produk() {
  const dispatch = useDispatch();
  const produk = useSelector((state) => state.produkReducer);
  const auth = useSelector((state) => state.authReducer);
  const [listProduk, setListProduk] = React.useState([]);
  const [listProdukUpgrade, setListProdukUpgrade] = React.useState([]);
  const [listProdukPo, setListProdukPo] = React.useState([]);
  const [listProdukUpgradePo, setListProdukUpgradePo] = React.useState([]);
  const [idProduk, setIdProduk] = React.useState("");
  const [jumlahPo, setJumlahPo] = React.useState(0);
  const [totalHargaPo, setTotalHargaPo] = React.useState(0);
  const [validasiJumlahPo, setValidasiJumlahPo] = React.useState(false);
  const [kodeProduk, setKodeProduk] = React.useState("");
  const [namaProduk, setNamaProduk] = React.useState("");
  const [kapasitasProduk, setKapasitasProduk] = React.useState("");
  const [tipeProduk, setTipeProduk] = React.useState("");
  const [kategoriProduk, setKategoriProduk] = React.useState("");
  const [reminderStok, setReminderStok] = React.useState(false);
  const [hargaProduk, setHargaProduk] = React.useState(0);
  const [hargaBeli, setHargaBeli] = React.useState(0);
  const [jumlahStock, setJumlahStock] = React.useState(0);
  const [minimalStock, setMinimalStock] = React.useState(0);
  const [fotoProduk, setFotoProduk] = React.useState(null);
  const [editFoto, setEditFoto] = React.useState(false);
  const fileInputRef = React.useRef(null);
  const [excel, setExcel] = React.useState([]);
  const [modal, setModal] = React.useState(false);
  const [modalImport, setModalImport] = React.useState(false);
  const [pending, setPending] = React.useState(false);

  const [modalDetail, setModalDetail] = React.useState(false);
  const [modalEdit, setModalEdit] = React.useState(false);
  const [modalPo, setModalPo] = React.useState(false);
  const [detailProduk, setDetailProduk] = React.useState(null);

  const [totalPerHalaman, setTotalPerHalaman] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [searchText, setSearchText] = React.useState("");
  const [totalPerHalamanUpgrade, setTotalPerHalamanUpgrade] =
    React.useState(10);
  const [pageUpgrade, setPageUpgrade] = React.useState(0);
  const [searchTextUpgrade, setSearchTextUpgrade] = React.useState("");

  const [totalPerHalamanPo, setTotalPerHalamanPo] = React.useState(10);
  const [pagePo, setPagePo] = React.useState(0);
  const [searchTextPo, setSearchTextPo] = React.useState("");
  const [totalPerHalamanUpgradePo, setTotalPerHalamanUpgradePo] =
    React.useState(10);
  const [pageUpgradePo, setPageUpgradePo] = React.useState(0);
  const [searchTextUpgradePo, setSearchTextUpgradePo] = React.useState("");
  function handleFileChange(e) {
    const selectedFiles = Array.from(e.target.files);
    setExcel(selectedFiles);
  }

  const removeExcel = (index) => {
    setExcel(excel.filter((_, i) => i !== index));
  };
  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };
  const handleClearSearch = () => {
    setSearchText("");
  };
  const handleSearchUpgrade = (e) => {
    setSearchTextUpgrade(e.target.value);
  };
  const handleClearSearchUpgrade = () => {
    setSearchTextUpgrade("");
  };

  const handleSearchPo = (e) => {
    setSearchTextPo(e.target.value);
  };
  const handleClearSearchPo = () => {
    setSearchTextPo("");
  };
  const handleSearchUpgradePo = (e) => {
    setSearchTextUpgradePo(e.target.value);
  };
  const handleClearSearchUpgradePo = () => {
    setSearchTextUpgradePo("");
  };
  const options = [
    { value: 10, label: "10" },
    { value: 50, label: "50" },
    { value: 100, label: "100" },
  ];
  const optionsUpgrade = [
    { value: 10, label: "10" },
    { value: 50, label: "50" },
    { value: 100, label: "100" },
  ];
  const optionsPo = [
    { value: 10, label: "10" },
    { value: 50, label: "50" },
    { value: 100, label: "100" },
  ];
  const optionsUpgradePo = [
    { value: 10, label: "10" },
    { value: 50, label: "50" },
    { value: 100, label: "100" },
  ];
  const handlePageSizeChange = (selectedOption) => {
    setTotalPerHalaman(selectedOption.value);
  };
  const handlePageSizeChangeUpgrade = (selectedOption) => {
    setTotalPerHalamanUpgrade(selectedOption.value);
  };

  const handlePageSizeChangePo = (selectedOption) => {
    setTotalPerHalamanPo(selectedOption.value);
  };
  const handlePageSizeChangeUpgradePo = (selectedOption) => {
    setTotalPerHalamanUpgradePo(selectedOption.value);
  };

  const customStyles = {
    rows: {
      style: {
        height: 50, // override the row height
      },
    },
    headRow: {
      style: {
        minHeight: 35,
        backgroundColor: "#F8F8F8",
        // fontFamily: "Poppins",
        justifyContent: "center",
      },
    },
    headCells: {
      style: {
        minHeight: 50,
        fontWeight: "bold",
        fontSize: 14,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
    cells: {
      style: {
        minHeight: 50,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
  };

  const conditionalRowStyles = [
    {
      when: (row) => row.jumlahStock <= row.minimalStock && row.mustPo,
      style: {
        backgroundColor: "rgba(255, 3, 3, 0.15)",
        color: "#000000",
        // "&:hover": {
        //   cursor: "pointer",
        // },
      },
    },
    {
      when: (row) => row.statusPo,
      style: {
        backgroundColor: "rgba(0, 252, 67, 0.15)",
        color: "#000000",
        // "&:hover": {
        //   cursor: "pointer",
        // },
      },
    },
  ];

  const columns = [
    {
      name: "No",
      width: "80px",
      center: true,
      selector: (row, index) => index + produk.listProduk.pagingCounter,
    },
    {
      name: "Aksi",
      width: "200px",
      center: true,
      selector: (row) => {
        return (
          <>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                onClick={() => {
                  setModalDetail(true);
                  setDetailProduk(row);
                }}
                style={{
                  width: 25,
                  height: 25,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  margin: 10,
                }}
              >
                <OverlayTrigger
                  overlay={<Tooltip id="tooltip-48903503">Detail</Tooltip>}
                >
                  <i
                    style={{ color: "#447DF7", fontSize: 16 }}
                    className="fas fa-info-circle"
                  ></i>
                </OverlayTrigger>
              </div>
              {(auth.responLogin && auth.responLogin.role === "Super Admin") ||
              (auth.responLogin && auth.responLogin.role === "Management") ? (
                <div
                  onClick={() => {
                    setModalEdit(true);
                    setIdProduk(row.idProduk);
                    setKodeProduk(row.kodeProduk);
                    setNamaProduk(row.namaProduk);
                    setKapasitasProduk(row.kapasitas);
                    setTipeProduk(row.tipe);
                    setMinimalStock(row.minimalStock);
                    setHargaProduk(row.hargaProduk);
                    setHargaBeli(row.hargaBeli);
                    let tmpKategori =
                      row.kategoriProduk === "utama"
                        ? { value: "utama", label: "Utama" }
                        : { value: "upgrade", label: "Upgrade" };

                    setKategoriProduk(tmpKategori);
                    let tmpReminder =
                      row.mustPo === true
                        ? { value: true, label: "Ya" }
                        : { value: false, label: "Tidak" };
                    setReminderStok(tmpReminder);
                  }}
                  style={{
                    width: 25,
                    height: 25,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    margin: 10,
                  }}
                >
                  <OverlayTrigger
                    overlay={<Tooltip id="tooltip-48903503">Edit </Tooltip>}
                  >
                    <i
                      style={{ color: "#478964", fontSize: 16 }}
                      className="fas fa-edit"
                    ></i>
                  </OverlayTrigger>
                </div>
              ) : null}
              {(auth.responLogin && auth.responLogin.role === "Super Admin") ||
              (auth.responLogin && auth.responLogin.role === "Management") ? (
                <div
                  onClick={() => {
                    Swal.fire({
                      title: `Apakah anda yakin untuk menghapus produk ini?`,
                      text: "Pastikan yang anda lakukan sudah benar!",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonColor: "#3085d6",
                      cancelButtonColor: "#d33",
                      confirmButtonText: "Ok!",
                      cancelButtonText: "Batal!",
                    }).then((result) => {
                      if (result.isConfirmed) {
                        Swal.fire({
                          title: "Loading...!",
                          text: "Proses hapus produk sedang dilakukan,tunggu proses hingga selesai!",
                          icon: "warning",
                          allowOutsideClick: false,
                          didOpen: () => {
                            Swal.showLoading();
                          },
                        });
                        deleteProduk({ idProduk: row.idProduk }).then(
                          (response) => {
                            if (response.data.status === 200) {
                              Swal.fire({
                                title: response.data.message,
                                icon: "success",
                                showConfirmButton: false,
                                timer: 1500,
                              });
                              getProduk(dispatch, {
                                cari: searchText,
                                page: page + 1,
                                limit: totalPerHalaman,
                              });
                            } else {
                              setPending(false);
                              Swal.fire({
                                icon: "error",
                                title: "Oops...",
                                text: !response.data
                                  ? "Gagal hapus produk"
                                  : response.data.message,
                              });
                            }
                          }
                        );
                      }
                    });
                  }}
                  style={{
                    width: 25,
                    height: 25,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    margin: 10,
                  }}
                >
                  <OverlayTrigger
                    overlay={<Tooltip id="tooltip-48903503">Hapus</Tooltip>}
                  >
                    <i
                      style={{ color: "#FF0000", fontSize: 16 }}
                      className="fas fa-trash"
                    ></i>
                  </OverlayTrigger>
                </div>
              ) : null}

              {/* <div
                style={{
                  width: 25,
                  height: 25,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  margin: 10,
                }}
              >
                <OverlayTrigger
                  overlay={<Tooltip id="tooltip-48903503">Hapus</Tooltip>}
                >
                  <i
                    style={{ color: "#FF0000", fontSize: 16 }}
                    className="fas fa-trash"
                  ></i>
                </OverlayTrigger>
              </div> */}
            </div>
          </>
        );
      },
    },
    {
      name: "Reminder Stok",
      width: "200px",
      center: true,
      selector: (row) => (row.mustPo ? "Ya" : "Tidak"),
    },
    {
      name: "Kode Mesin",
      width: "200px",
      center: true,
      selector: (row) => row.kodeProduk,
    },
    {
      name: "Nama Mesin",
      width: "300px",
      center: true,
      selector: (row) => row.namaProduk,
    },
    {
      name: "Kapasitas/Jenis",
      width: "200px",
      center: true,
      selector: (row) => row.kapasitas,
    },
    {
      name: "Tipe",
      width: "200px",
      center: true,
      selector: (row) => row.tipe,
    },
    {
      name: "Kategori Mesin",
      width: "200px",
      center: true,
      selector: (row) => row.kategoriProduk,
    },
    {
      name: "Harga",
      width: "200px",
      center: true,
      selector: (row) => {
        const formattedValue = new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        }).format(row.hargaProduk);
        return formattedValue;
      },
    },
    {
      name: "Harga Beli",
      width: "200px",
      center: true,
      selector: (row) => {
        const formattedValue = new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        }).format(!row.hargaBeli ? 0 : row.hargaBeli);
        return formattedValue;
      },
      omit:
        (auth.responLogin && auth.responLogin.role === "Super Admin") ||
        (auth.responLogin && auth.responLogin.role === "Management")
          ? false
          : true,
    },
    {
      name: "Stock Gudang",
      width: "200px",
      center: true,
      selector: (row) => row.jumlahStock,
    },
    {
      name: "Stock Sales",
      width: "200px",
      center: true,
      selector: (row) => row.stockSales,
    },
    {
      name: "Minimal Stock",
      width: "200px",
      center: true,
      selector: (row) => row.minimalStock,
    },
    {
      name: "Tanggal Data Dibuat",
      width: "200px",
      center: true,
      selector: (row) =>
        moments
          .tz(new Date(row.createdAt), "Asia/Jakarta")
          .format("DD-MM-YYYY HH:mm:ss"),
    },
    {
      name: "Tanggal Update Data",
      width: "200px",
      center: true,
      selector: (row) =>
        moments
          .tz(new Date(row.updatedAt), "Asia/Jakarta")
          .format("DD-MM-YYYY HH:mm:ss"),
    },
  ];

  const columnsUpgrade = [
    {
      name: "No",
      width: "80px",
      center: true,
      selector: (row, index) => index + produk.listProdukUpgrade.pagingCounter,
    },
    {
      name: "Aksi",
      width: "200px",
      center: true,
      selector: (row) => {
        return (
          <>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                onClick={() => {
                  setModalDetail(true);
                  setDetailProduk(row);
                }}
                style={{
                  width: 25,
                  height: 25,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  margin: 10,
                }}
              >
                <OverlayTrigger
                  overlay={<Tooltip id="tooltip-48903503">Detail</Tooltip>}
                >
                  <i
                    style={{ color: "#447DF7", fontSize: 16 }}
                    className="fas fa-info-circle"
                  ></i>
                </OverlayTrigger>
              </div>
              {(auth.responLogin && auth.responLogin.role === "Super Admin") ||
              (auth.responLogin && auth.responLogin.role === "Management") ? (
                <div
                  onClick={() => {
                    setModalEdit(true);
                    setIdProduk(row.idProduk);
                    setKodeProduk(row.kodeProduk);
                    setNamaProduk(row.namaProduk);
                    setKapasitasProduk(row.kapasitas);
                    setTipeProduk(row.tipe);
                    setMinimalStock(row.minimalStock);
                    setHargaProduk(row.hargaProduk);
                    setHargaBeli(row.hargaBeli);
                    let tmpKategori =
                      row.kategoriProduk === "utama"
                        ? { value: "utama", label: "Utama" }
                        : { value: "upgrade", label: "Upgrade" };

                    setKategoriProduk(tmpKategori);
                    let tmpReminder =
                      row.mustPo === true
                        ? { value: true, label: "Ya" }
                        : { value: false, label: "Tidak" };
                    setReminderStok(tmpReminder);
                  }}
                  style={{
                    width: 25,
                    height: 25,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    margin: 10,
                  }}
                >
                  <OverlayTrigger
                    overlay={<Tooltip id="tooltip-48903503">Edit </Tooltip>}
                  >
                    <i
                      style={{ color: "#478964", fontSize: 16 }}
                      className="fas fa-edit"
                    ></i>
                  </OverlayTrigger>
                </div>
              ) : null}
              {(auth.responLogin && auth.responLogin.role === "Super Admin") ||
              (auth.responLogin && auth.responLogin.role === "Management") ? (
                <div
                  onClick={() => {
                    Swal.fire({
                      title: `Apakah anda yakin untuk menghapus produk ini?`,
                      text: "Pastikan yang anda lakukan sudah benar!",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonColor: "#3085d6",
                      cancelButtonColor: "#d33",
                      confirmButtonText: "Ok!",
                      cancelButtonText: "Batal!",
                    }).then((result) => {
                      if (result.isConfirmed) {
                        Swal.fire({
                          title: "Loading...!",
                          text: "Proses hapus produk sedang dilakukan,tunggu proses hingga selesai!",
                          icon: "warning",
                          allowOutsideClick: false,
                          didOpen: () => {
                            Swal.showLoading();
                          },
                        });
                        deleteProduk({ idProduk: row.idProduk }).then(
                          (response) => {
                            if (response.data.status === 200) {
                              Swal.fire({
                                title: response.data.message,
                                icon: "success",
                                showConfirmButton: false,
                                timer: 1500,
                              });
                              getProdukUpgrade(dispatch, {
                                cari: searchTextUpgrade,
                                page: pageUpgrade + 1,
                                limit: totalPerHalamanUpgrade,
                              });
                            } else {
                              setPending(false);
                              Swal.fire({
                                icon: "error",
                                title: "Oops...",
                                text: !response.data
                                  ? "Gagal hapus produk"
                                  : response.data.message,
                              });
                            }
                          }
                        );
                      }
                    });
                  }}
                  style={{
                    width: 25,
                    height: 25,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    margin: 10,
                  }}
                >
                  <OverlayTrigger
                    overlay={<Tooltip id="tooltip-48903503">Hapus</Tooltip>}
                  >
                    <i
                      style={{ color: "#FF0000", fontSize: 16 }}
                      className="fas fa-trash"
                    ></i>
                  </OverlayTrigger>
                </div>
              ) : null}

              {/* <div
                style={{
                  width: 25,
                  height: 25,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  margin: 10,
                }}
              >
                <OverlayTrigger
                  overlay={<Tooltip id="tooltip-48903503">Hapus</Tooltip>}
                >
                  <i
                    style={{ color: "#FF0000", fontSize: 16 }}
                    className="fas fa-trash"
                  ></i>
                </OverlayTrigger>
              </div> */}
            </div>
          </>
        );
      },
    },
    {
      name: "Reminder Stok",
      width: "200px",
      center: true,
      selector: (row) => (row.mustPo ? "Ya" : "Tidak"),
    },
    {
      name: "Kode Mesin",
      width: "200px",
      center: true,
      selector: (row) => row.kodeProduk,
    },
    {
      name: "Nama Mesin",
      width: "300px",
      center: true,
      selector: (row) => row.namaProduk,
    },
    {
      name: "Kapasitas/Jenis",
      width: "200px",
      center: true,
      selector: (row) => row.kapasitas,
    },
    {
      name: "Tipe",
      width: "200px",
      center: true,
      selector: (row) => row.tipe,
    },
    {
      name: "Kategori Mesin",
      width: "200px",
      center: true,
      selector: (row) => row.kategoriProduk,
    },
    {
      name: "Harga",
      width: "200px",
      center: true,
      selector: (row) => {
        const formattedValue = new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        }).format(row.hargaProduk);
        return formattedValue;
      },
    },
    {
      name: "Harga Beli",
      width: "200px",
      center: true,
      selector: (row) => {
        const formattedValue = new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        }).format(!row.hargaBeli ? 0 : row.hargaBeli);
        return formattedValue;
      },
      omit:
        (auth.responLogin && auth.responLogin.role === "Super Admin") ||
        (auth.responLogin && auth.responLogin.role === "Management")
          ? false
          : true,
    },
    {
      name: "Stock Gudang",
      width: "200px",
      center: true,
      selector: (row) => row.jumlahStock,
    },
    {
      name: "Stock Sales",
      width: "200px",
      center: true,
      selector: (row) => row.stockSales,
    },
    {
      name: "Minimal Stock",
      width: "200px",
      center: true,
      selector: (row) => row.minimalStock,
    },
    {
      name: "Tanggal Data Dibuat",
      width: "200px",
      center: true,
      selector: (row) =>
        moments
          .tz(new Date(row.createdAt), "Asia/Jakarta")
          .format("DD-MM-YYYY HH:mm:ss"),
    },
    {
      name: "Tanggal Update Data",
      width: "200px",
      center: true,
      selector: (row) =>
        moments
          .tz(new Date(row.updatedAt), "Asia/Jakarta")
          .format("DD-MM-YYYY HH:mm:ss"),
    },
  ];

  const columnsPo = [
    {
      name: "No",
      width: "80px",
      center: true,
      selector: (row, index) => index + produk.listProdukPo.pagingCounter,
    },
    {
      name: "Aksi",
      width: "200px",
      center: true,
      selector: (row) => {
        return (
          <>
            <div style={{ display: "flex", flexDirection: "row" }}>
              {/* <div
                onClick={() => {
                  setModalDetail(true);
                  setDetailProduk(row);
                }}
                style={{
                  width: 25,
                  height: 25,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  margin: 10,
                }}
              >
                <OverlayTrigger
                  overlay={<Tooltip id="tooltip-48903503">Detail</Tooltip>}
                >
                  <i
                    style={{ color: "#447DF7", fontSize: 16 }}
                    className="fas fa-info-circle"
                  ></i>
                </OverlayTrigger>
              </div> */}
              <div
                onClick={() => {
                  if (row.hargaBeli === 0) {
                    Swal.fire({
                      icon: "warning",
                      title: "Oops...",
                      text: "Maaf harga beli belum diinput, silahkan input harga beli produk!!!",
                    });
                  } else {
                    setModalPo(true);
                    setKodeProduk(row.kodeProduk);
                    setNamaProduk(row.namaProduk);
                    setIdProduk(row.idProduk);
                    setHargaBeli(row.hargaBeli);
                  }
                }}
                style={{
                  width: 25,
                  height: 25,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  margin: 10,
                }}
              >
                <OverlayTrigger
                  overlay={<Tooltip id="tooltip-48903503">Tambah PO </Tooltip>}
                >
                  <i
                    style={{ color: "#478964", fontSize: 16 }}
                    className="fas fa-plus"
                  ></i>
                </OverlayTrigger>
              </div>
              {/* <div
                style={{
                  width: 25,
                  height: 25,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  margin: 10,
                }}
              >
                <OverlayTrigger
                  overlay={<Tooltip id="tooltip-48903503">Hapus</Tooltip>}
                >
                  <i
                    style={{ color: "#FF0000", fontSize: 16 }}
                    className="fas fa-trash"
                  ></i>
                </OverlayTrigger>
              </div> */}
            </div>
          </>
        );
      },
    },
    {
      name: "Kode Mesin",
      width: "200px",
      center: true,
      selector: (row) => row.kodeProduk,
    },
    {
      name: "Nama Mesin",
      width: "300px",
      center: true,
      selector: (row) => row.namaProduk,
    },
    {
      name: "Kapasitas/Jenis",
      width: "200px",
      center: true,
      selector: (row) => row.kapasitas,
    },
    {
      name: "Tipe",
      width: "200px",
      center: true,
      selector: (row) => row.tipe,
    },
    {
      name: "Kategori Mesin",
      width: "200px",
      center: true,
      selector: (row) => row.kategoriProduk,
    },
    {
      name: "Harga",
      width: "200px",
      center: true,
      selector: (row) => {
        const formattedValue = new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        }).format(row.hargaProduk);
        return formattedValue;
      },
    },
    {
      name: "Harga Beli",
      width: "200px",
      center: true,
      selector: (row) => {
        const formattedValue = new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        }).format(!row.hargaBeli ? 0 : row.hargaBeli);
        return formattedValue;
      },
      omit:
        (auth.responLogin && auth.responLogin.role === "Super Admin") ||
        (auth.responLogin && auth.responLogin.role === "Management")
          ? false
          : true,
    },
    {
      name: "Stock Gudang",
      width: "200px",
      center: true,
      selector: (row) => row.jumlahStock,
    },
    {
      name: "Stock Sales",
      width: "200px",
      center: true,
      selector: (row) => row.stockSales,
    },
    {
      name: "Minimal Stock",
      width: "200px",
      center: true,
      selector: (row) => row.minimalStock,
    },
    {
      name: "Tanggal Data Dibuat",
      width: "200px",
      center: true,
      selector: (row) =>
        moments
          .tz(new Date(row.createdAt), "Asia/Jakarta")
          .format("DD-MM-YYYY HH:mm:ss"),
    },
    {
      name: "Tanggal Update Data",
      width: "200px",
      center: true,
      selector: (row) =>
        moments
          .tz(new Date(row.updatedAt), "Asia/Jakarta")
          .format("DD-MM-YYYY HH:mm:ss"),
    },
  ];

  const columnsUpgradePo = [
    {
      name: "No",
      width: "80px",
      center: true,
      selector: (row, index) =>
        index + produk.listProdukUpgradePo.pagingCounter,
    },
    {
      name: "Aksi",
      width: "200px",
      center: true,
      selector: (row) => {
        return (
          <>
            <div style={{ display: "flex", flexDirection: "row" }}>
              {/* <div
                onClick={() => {
                  setModalDetail(true);
                  setDetailProduk(row);
                }}
                style={{
                  width: 25,
                  height: 25,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  margin: 10,
                }}
              >
                <OverlayTrigger
                  overlay={<Tooltip id="tooltip-48903503">Detail</Tooltip>}
                >
                  <i
                    style={{ color: "#447DF7", fontSize: 16 }}
                    className="fas fa-info-circle"
                  ></i>
                </OverlayTrigger>
              </div> */}
              <div
                onClick={() => {
                  setModalPo(true);
                  setKodeProduk(row.kodeProduk);
                  setNamaProduk(row.namaProduk);
                  setIdProduk(row.idProduk);
                }}
                style={{
                  width: 25,
                  height: 25,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  margin: 10,
                }}
              >
                <OverlayTrigger
                  overlay={<Tooltip id="tooltip-48903503">Tambah PO </Tooltip>}
                >
                  <i
                    style={{ color: "#478964", fontSize: 16 }}
                    className="fas fa-plus"
                  ></i>
                </OverlayTrigger>
              </div>
              {/* <div
                style={{
                  width: 25,
                  height: 25,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  margin: 10,
                }}
              >
                <OverlayTrigger
                  overlay={<Tooltip id="tooltip-48903503">Hapus</Tooltip>}
                >
                  <i
                    style={{ color: "#FF0000", fontSize: 16 }}
                    className="fas fa-trash"
                  ></i>
                </OverlayTrigger>
              </div> */}
            </div>
          </>
        );
      },
    },
    {
      name: "Kode Mesin",
      width: "200px",
      center: true,
      selector: (row) => row.kodeProduk,
    },
    {
      name: "Nama Mesin",
      width: "300px",
      center: true,
      selector: (row) => row.namaProduk,
    },
    {
      name: "Kapasitas/Jenis",
      width: "200px",
      center: true,
      selector: (row) => row.kapasitas,
    },
    {
      name: "Tipe",
      width: "200px",
      center: true,
      selector: (row) => row.tipe,
    },
    {
      name: "Kategori Mesin",
      width: "200px",
      center: true,
      selector: (row) => row.kategoriProduk,
    },
    {
      name: "Harga",
      width: "200px",
      center: true,
      selector: (row) => {
        const formattedValue = new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        }).format(row.hargaProduk);
        return formattedValue;
      },
    },
    {
      name: "Harga Beli",
      width: "200px",
      center: true,
      selector: (row) => {
        const formattedValue = new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        }).format(!row.hargaBeli ? 0 : row.hargaBeli);
        return formattedValue;
      },
      omit:
        (auth.responLogin && auth.responLogin.role === "Super Admin") ||
        (auth.responLogin && auth.responLogin.role === "Management")
          ? false
          : true,
    },
    {
      name: "Stock Gudang",
      width: "200px",
      center: true,
      selector: (row) => row.jumlahStock,
    },
    {
      name: "Stock Sales",
      width: "200px",
      center: true,
      selector: (row) => row.stockSales,
    },
    {
      name: "Minimal Stock",
      width: "200px",
      center: true,
      selector: (row) => row.minimalStock,
    },
    {
      name: "Tanggal Data Dibuat",
      width: "200px",
      center: true,
      selector: (row) =>
        moments
          .tz(new Date(row.createdAt), "Asia/Jakarta")
          .format("DD-MM-YYYY HH:mm:ss"),
    },
    {
      name: "Tanggal Update Data",
      width: "200px",
      center: true,
      selector: (row) =>
        moments
          .tz(new Date(row.updatedAt), "Asia/Jakarta")
          .format("DD-MM-YYYY HH:mm:ss"),
    },
  ];

  const addProduk = () => {
    setPending(true);
    Swal.fire({
      title: "Loading...!",
      text: "Penambahan produk sedang berlangsung,tunggu proses hingga selesai!",
      icon: "warning",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    let formData = new FormData();
    formData.append("kodeProduk", kodeProduk);
    formData.append("namaProduk", namaProduk);
    formData.append("kapasitas", kapasitasProduk);
    formData.append("kategoriProduk", kategoriProduk.value);
    formData.append("tipe", tipeProduk);
    formData.append("hargaProduk", hargaProduk);
    formData.append("hargaBeli", hargaBeli);
    formData.append("jumlahStock", jumlahStock);
    formData.append("minimalStock", minimalStock);
    formData.append("fotoProduk", fotoProduk);
    tambahProduk(formData).then((response) => {
      if (response.data.status === 200) {
        setPending(false);
        setNamaProduk("");
        setKapasitasProduk("");
        setTipeProduk("");
        setHargaProduk(0);
        setHargaBeli(0);
        setJumlahStock(0);
        setFotoProduk(null);
        setKodeProduk("");
        setMinimalStock(0);
        setKategoriProduk("");
        setReminderStok(false);

        setModal(false);
        getProduk(dispatch, {
          cari: searchText,
          page: page + 1,
          limit: totalPerHalaman,
        });
        getProdukUpgrade(dispatch, {
          cari: searchTextUpgrade,
          page: pageUpgrade + 1,
          limit: totalPerHalamanUpgrade,
        });
        Swal.fire({
          title: response.data.message,
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        setPending(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: !response.data
            ? "Gagal menambahkan Produk"
            : response.data.message,
        });
      }
    });
  };

  const kirimEditProduk = () => {
    setPending(true);
    Swal.fire({
      title: "Loading...!",
      text: "Edit data produk sedang berlangsung,tunggu proses hingga selesai!",
      icon: "warning",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    let formData = new FormData();
    formData.append("idProduk", idProduk);
    formData.append("kodeProduk", kodeProduk);
    formData.append("namaProduk", namaProduk);
    formData.append("kapasitas", kapasitasProduk);
    formData.append("tipe", tipeProduk);
    formData.append("hargaProduk", hargaProduk);
    formData.append("hargaBeli", hargaBeli);
    formData.append("penambahanStock", jumlahStock);
    formData.append("minimalStock", minimalStock);
    formData.append("updateFoto", editFoto);
    formData.append("kategoriProduk", kategoriProduk.value);
    formData.append("reminder", reminderStok.value);
    formData.append("fotoProduk", fotoProduk);
    editProduk(formData).then((response) => {
      if (response.data.status === 200) {
        setPending(false);
        setIdProduk("");
        setNamaProduk("");
        setKapasitasProduk("");
        setTipeProduk("");
        setHargaProduk(0);
        setHargaBeli(0);
        setJumlahStock(0);
        setFotoProduk(null);
        setKodeProduk("");
        setEditFoto(false);
        setKategoriProduk("");
        setMinimalStock(0);
        setReminderStok(false);

        setModalEdit(false);
        getProduk(dispatch, {
          cari: searchText,
          page: page + 1,
          limit: totalPerHalaman,
        });
        getProdukUpgrade(dispatch, {
          cari: searchTextUpgrade,
          page: pageUpgrade + 1,
          limit: totalPerHalamanUpgrade,
        });
        Swal.fire({
          title: response.data.message,
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        setPending(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: !response.data
            ? "Gagal menambahkan Produk"
            : response.data.message,
        });
      }
    });
  };

  const importFile = async (e) => {
    e.preventDefault();

    setPending(true);
    const formData = new FormData();

    excel.forEach((file) => {
      formData.append("excelFile", file);
    });

    await importProduk(formData).then((response) => {
      if (response.data.status === 200) {
        setPending(false);
        setExcel([]);
        setModalImport(false);
        getProduk(dispatch, {
          cari: searchText,
          page: page + 1,
          limit: totalPerHalaman,
        });
        getProdukUpgrade(dispatch, {
          cari: searchTextUpgrade,
          page: pageUpgrade + 1,
          limit: totalPerHalamanUpgrade,
        });
        Swal.fire({
          title: "Sukses",
          text: response.data.message,
          icon: "success",
          showConfirmButton: true,
          // timer: 1500,
        });
      } else {
        setPending(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: !response.data ? "Gagal import produk" : response.data.message,
        });
      }
    });
  };

  const addPo = () => {
    if (jumlahPo <= 0 || jumlahPo === "") {
      setValidasiJumlahPo(true);
    } else {
      setValidasiJumlahPo(false);
      setPending(true);
      Swal.fire({
        title: "Loading...!",
        text: "Penambahan PO sedang berlangsung,tunggu proses hingga selesai!",
        icon: "warning",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      let data = {
        idProduk: idProduk,
        kodeProduk: kodeProduk,
        namaProduk: namaProduk,
        qty: jumlahPo,
        hargaSatuan: hargaBeli,
        totalHarga: totalHargaPo,
        tglOrder: moments.tz(new Date(), "Asia/Jakarta").format("YYYY-MM-DD"),
      };

      tambahPo(data).then((response) => {
        if (response.data.status === 200) {
          setPending(false);
          setNamaProduk("");
          setKodeProduk("");
          setIdProduk("");
          setJumlahPo(0);
          setHargaBeli(0);
          setTotalHargaPo(0);
          setValidasiJumlahPo(false);

          setModalPo(false);
          getProduk(dispatch, {
            cari: searchText,
            page: page + 1,
            limit: totalPerHalaman,
          });
          getProdukUpgrade(dispatch, {
            cari: searchTextUpgrade,
            page: pageUpgrade + 1,
            limit: totalPerHalamanUpgrade,
          });
          getProdukPo(dispatch, {
            cari: searchTextPo,
            page: pagePo + 1,
            limit: totalPerHalamanPo,
          });
          getProdukUpgradePo(dispatch, {
            cari: searchTextUpgradePo,
            page: pageUpgradePo + 1,
            limit: totalPerHalamanUpgradePo,
          });
          Swal.fire({
            title: response.data.message,
            icon: "success",
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          setPending(false);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: !response.data ? "Gagal membuat PO" : response.data.message,
          });
        }
      });
    }
  };

  const history = useHistory();

  React.useEffect(() => {
    let tmp =
      produk.listProduk.data &&
      produk.listProduk.data.map((val, index) => {
        return {
          ...val,
        };
      });
    setListProduk(tmp);
    if (produk.listProduk.length != 0) {
      setPending(false);
    }
  }, [produk.listProduk]);

  React.useEffect(() => {
    let tmp =
      produk.listProdukUpgrade.data &&
      produk.listProdukUpgrade.data.map((val, index) => {
        return {
          ...val,
        };
      });
    setListProdukUpgrade(tmp);
    if (produk.listProdukUpgrade.length != 0) {
      setPending(false);
    }
  }, [produk.listProdukUpgrade]);

  React.useEffect(() => {
    getProduk(dispatch, {
      cari: searchText,
      page: page + 1,
      limit: totalPerHalaman,
    });
  }, [page, totalPerHalaman, searchText]);

  React.useEffect(() => {
    getProdukUpgrade(dispatch, {
      cari: searchTextUpgrade,
      page: pageUpgrade + 1,
      limit: totalPerHalamanUpgrade,
    });
  }, [pageUpgrade, totalPerHalamanUpgrade, searchTextUpgrade]);

  React.useEffect(() => {
    let tmp =
      produk.listProdukPo.data &&
      produk.listProdukPo.data.map((val, index) => {
        return {
          ...val,
        };
      });
    setListProdukPo(tmp);
    if (produk.listProdukPo.length != 0) {
      setPending(false);
    }
  }, [produk.listProdukPo]);

  React.useEffect(() => {
    let tmp =
      produk.listProdukUpgradePo.data &&
      produk.listProdukUpgradePo.data.map((val, index) => {
        return {
          ...val,
        };
      });
    setListProdukUpgradePo(tmp);
    if (produk.listProdukUpgradePo.length != 0) {
      setPending(false);
    }
  }, [produk.listProdukUpgradePo]);

  React.useEffect(() => {
    getProdukPo(dispatch, {
      cari: searchTextPo,
      page: pagePo + 1,
      limit: totalPerHalamanPo,
    });
  }, [pagePo, totalPerHalamanPo, searchTextPo]);

  React.useEffect(() => {
    getProdukUpgradePo(dispatch, {
      cari: searchTextUpgradePo,
      page: pageUpgradePo + 1,
      limit: totalPerHalamanUpgradePo,
    });
  }, [pageUpgradePo, totalPerHalamanUpgradePo, searchTextUpgradePo]);

  React.useEffect(() => {
    getTotalProdukPo(dispatch);
  }, []);

  const exportAllMesin = async () => {
    Swal.fire({
      title: "Loading...!",
      text: "Download data mesin sedng berlangsung,tunggu proses hingga selesai!",
      icon: "warning",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    exportAllProduk(dispatch).then((respon) => {
      if (respon.status === 200) {
        const url = window.URL.createObjectURL(new Blob([respon.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Export Mesin ${moments
            .tz(new Date(), "Asia/Jakarta")
            .format("DD-MM-YYYY HHmmss")}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        Swal.fire({
          title: "Download Berhasil",
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: !respon.data
            ? "Gagal download data mesin"
            : respon.data.message,
        });
      }
    });
  };
  return (
    <>
      <Container fluid>
        <Tab.Container id="icons-tabs-example" defaultActiveKey="info-icons">
          <Nav role="tablist" variant="tabs">
            <Nav.Item>
              <Nav.Link eventKey="info-icons">
                <i className="fas fa-box-open"></i> Produk
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="account-icons">
                <i className="fas fa-store"></i> Harus PO
                {produk.totalProdukPo === 0 ? null : (
                  <span
                    style={{
                      backgroundColor: "red",
                      marginLeft: 5,
                      marginRight: 5,
                      borderRadius: 10,
                      padding: 8,
                      color: "#FFFFFF",
                      fontWeight: "bold",
                      fontSize: 12,
                    }}
                  >
                    {produk.totalProdukPo}
                  </span>
                )}
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="info-icons">
              <Container fluid>
                <Row
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: 20,
                  }}
                >
                  <Col>
                    <div style={{ fontSize: 25, fontWeight: "bold" }}>
                      Produk
                    </div>
                  </Col>
                  <Col style={{ textAlign: "right" }}>
                    <Button
                      style={{
                        marginLeft: 20,
                        marginBottom: 0,
                        backgroundColor: "rgba(40,118,74,1)",
                        borderColor: "rgba(40,118,74,1)",
                      }}
                      onClick={() => {
                        exportAllMesin();
                      }}
                    >
                      <i
                        className="fas fa-file-excel"
                        style={{ marginRight: 10 }}
                      ></i>
                      Export Produk
                    </Button>
                    {(auth.responLogin &&
                      auth.responLogin.role === "Super Admin") ||
                    (auth.responLogin &&
                      auth.responLogin.role === "Management") ? (
                      <Button
                        style={{ marginLeft: 10, marginBottom: 0 }}
                        onClick={() => {
                          setModal(true);
                        }}
                      >
                        Tambah Produk
                      </Button>
                    ) : null}
                    {/* <Button
                      style={{ marginLeft: 10, marginBottom: 0 }}
                      onClick={() => {
                        setModal(true);
                      }}
                    >
                      Tambah Produk
                    </Button> */}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {(auth.responLogin &&
                      auth.responLogin.role === "Super Admin") ||
                    (auth.responLogin &&
                      auth.responLogin.role === "Management") ? (
                      <Button
                        onClick={() => {
                          setModalImport(true);
                        }}
                        className="btn-social"
                        variant="twitter"
                        style={{
                          backgroundColor: "rgba(40,118,74,1)",
                          borderColor: "rgba(40,118,74,1)",
                        }}
                      >
                        <i
                          className="fas fa-file-excel"
                          style={{ marginRight: 10 }}
                        ></i>
                        Import Excel
                      </Button>
                    ) : null}
                    <Button
                      onClick={() => {
                        window.open(
                          "https://docs.google.com/spreadsheets/d/1Qxfk5Xe0Xbjr39htdHLRoiA6_wSCEWaN/edit?usp=sharing&ouid=116475921970373937456&rtpof=true&sd=true",
                          "_blank"
                        );
                      }}
                      className="btn-social"
                      variant="twitter"
                      style={{
                        backgroundColor: "rgba(64,123,255,1)",
                        borderColor: "rgba(64,123,255,1)",
                        marginLeft: 10,
                      }}
                    >
                      <i
                        className="fas fa-cloud-download-alt"
                        style={{ marginRight: 10 }}
                      ></i>
                      Download Format Import
                    </Button>
                  </Col>
                  <Col>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        marginBottom: 20,
                      }}
                    >
                      <div>
                        <InputGroup>
                          <Form.Control
                            style={{
                              paddingLeft: "10px",
                              width: "300px",
                            }}
                            type="text"
                            value={searchText}
                            onChange={handleSearch}
                            placeholder="Nama Mesin/Kode Mesin"
                          />
                          <InputGroup.Append>
                            <InputGroup.Text
                              style={{ cursor: "pointer" }}
                              onClick={handleClearSearch}
                            >
                              <i class="fas fa-times"></i>
                            </InputGroup.Text>
                          </InputGroup.Append>
                        </InputGroup>
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col md="12">
                    <Card>
                      <Card.Body>
                        <DataTable
                          columns={columns}
                          data={listProduk}
                          //   pagination
                          customStyles={customStyles}
                          paginationPerPage={totalPerHalaman}
                          //   onChangePage={handlePageChange}
                          progressPending={pending}
                          conditionalRowStyles={conditionalRowStyles}
                        />

                        <div style={{ marginTop: 35 }}>
                          <Row
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Col>
                              <Row style={{ alignItems: "center" }}>
                                <div
                                  style={{
                                    paddingRight: 0,
                                    marginLeft: 20,
                                    marginRight: 10,
                                  }}
                                >
                                  <label
                                    style={{
                                      fontWeight: "bold",
                                      textTransform: "none",
                                      fontSize: 14,
                                    }}
                                  >
                                    Rows per page
                                  </label>
                                </div>
                                <Col sm="3" style={{ paddingLeft: 0 }}>
                                  <Select
                                    className="react-select primary"
                                    classNamePrefix="react-select"
                                    name="Pilih Client"
                                    value={options.find(
                                      (option) =>
                                        option.value === totalPerHalaman
                                    )}
                                    onChange={handlePageSizeChange}
                                    options={options}
                                    placeholder="Rows per page"
                                  />
                                </Col>
                              </Row>
                            </Col>

                            <Col>
                              <ReactPaginate
                                forcePage={page}
                                containerClassName="paginations justify-content-end"
                                previousClassName="pages-items-containers"
                                previousLinkClassName="pages-links-labels"
                                nextClassName="pages-items-containers"
                                nextLinkClassName="pages-links-labels"
                                breakClassName="pages-items-containers"
                                pageCount={produk.listProduk.totalPages}
                                pageRangeDisplayed={3}
                                marginPagesDisplayed={1}
                                pageLinkClassName="pages-links-labels"
                                pageClassName="pages-items-containers"
                                activeClassName="actives"
                                activeLinkClassName="activess"
                                nextLabel=" > "
                                previousLabel=" < "
                                initialPage={page}
                                disableInitialCallback={true}
                                onPageChange={(val) => {
                                  setPage(val.selected);
                                }}
                              />
                            </Col>
                          </Row>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Container>
              <hr style={{ borderWidth: 5 }}></hr>
              <Container fluid>
                <Row
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: 20,
                  }}
                >
                  <Col>
                    <div style={{ fontSize: 25, fontWeight: "bold" }}>
                      Produk Upgrade
                    </div>
                  </Col>

                  <Col style={{ textAlign: "right" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        marginBottom: 20,
                      }}
                    >
                      <div>
                        <InputGroup>
                          <Form.Control
                            style={{
                              paddingLeft: "10px",
                              width: "300px",
                            }}
                            type="text"
                            value={searchTextUpgrade}
                            onChange={handleSearchUpgrade}
                            placeholder="Nama Mesin/Kode Mesin"
                          />
                          <InputGroup.Append>
                            <InputGroup.Text
                              style={{ cursor: "pointer" }}
                              onClick={handleClearSearchUpgrade}
                            >
                              <i class="fas fa-times"></i>
                            </InputGroup.Text>
                          </InputGroup.Append>
                        </InputGroup>
                      </div>
                    </div>
                    {/* <Button
              style={{ marginLeft: 20, marginBottom: 0 }}
              onClick={() => {
                setModal(true);
              }}
            >
              Tambah Produk
            </Button> */}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {/* <Button
              onClick={() => {
                setModalImport(true);
              }}
              className="btn-social"
              variant="twitter"
              style={{
                backgroundColor: "rgba(40,118,74,1)",
                borderColor: "rgba(40,118,74,1)",
              }}
            >
              <i className="fas fa-file-excel" style={{ marginRight: 10 }}></i>
              Import Excel
            </Button>
            <Button
              onClick={() => {
                window.open(
                  "https://docs.google.com/spreadsheets/d/1Qxfk5Xe0Xbjr39htdHLRoiA6_wSCEWaN/edit?usp=sharing&ouid=116475921970373937456&rtpof=true&sd=true",
                  "_blank"
                );
              }}
              className="btn-social"
              variant="twitter"
              style={{
                backgroundColor: "rgba(64,123,255,1)",
                borderColor: "rgba(64,123,255,1)",
                marginLeft: 10,
              }}
            >
              <i
                className="fas fa-cloud-download-alt"
                style={{ marginRight: 10 }}
              ></i>
              Download Format Import
            </Button> */}
                  </Col>
                  <Col>
                    {/* <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                marginBottom: 20,
              }}
            >
              <div>
                <InputGroup>
                  <Form.Control
                    style={{
                      paddingLeft: "10px",
                      width: "300px",
                    }}
                    type="text"
                    value={searchTextUpgrade}
                    onChange={handleSearchUpgrade}
                    placeholder="Nama Mesin/Kode Mesin"
                  />
                  <InputGroup.Append>
                    <InputGroup.Text
                      style={{ cursor: "pointer" }}
                      onClick={handleClearSearchUpgrade}
                    >
                      <i class="fas fa-times"></i>
                    </InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </div>
            </div> */}
                  </Col>
                </Row>

                <Row>
                  <Col md="12">
                    <Card>
                      <Card.Body>
                        <DataTable
                          columns={columnsUpgrade}
                          data={listProdukUpgrade}
                          //   pagination
                          customStyles={customStyles}
                          paginationPerPage={totalPerHalamanUpgrade}
                          //   onChangePage={handlePageChange}
                          progressPending={pending}
                          conditionalRowStyles={conditionalRowStyles}
                        />

                        <div style={{ marginTop: 35 }}>
                          <Row
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Col>
                              <Row style={{ alignItems: "center" }}>
                                <div
                                  style={{
                                    paddingRight: 0,
                                    marginLeft: 20,
                                    marginRight: 10,
                                  }}
                                >
                                  <label
                                    style={{
                                      fontWeight: "bold",
                                      textTransform: "none",
                                      fontSize: 14,
                                    }}
                                  >
                                    Rows per page
                                  </label>
                                </div>
                                <Col sm="3" style={{ paddingLeft: 0 }}>
                                  <Select
                                    className="react-select primary"
                                    classNamePrefix="react-select"
                                    name="Pilih Client"
                                    value={optionsUpgrade.find(
                                      (option) =>
                                        option.value === totalPerHalamanUpgrade
                                    )}
                                    onChange={handlePageSizeChangeUpgrade}
                                    options={optionsUpgrade}
                                    placeholder="Rows per page"
                                  />
                                </Col>
                              </Row>
                            </Col>

                            <Col>
                              <ReactPaginate
                                forcePage={pageUpgrade}
                                containerClassName="paginations justify-content-end"
                                previousClassName="pages-items-containers"
                                previousLinkClassName="pages-links-labels"
                                nextClassName="pages-items-containers"
                                nextLinkClassName="pages-links-labels"
                                breakClassName="pages-items-containers"
                                pageCount={produk.listProdukUpgrade.totalPages}
                                pageRangeDisplayed={3}
                                marginPagesDisplayed={1}
                                pageLinkClassName="pages-links-labels"
                                pageClassName="pages-items-containers"
                                activeClassName="actives"
                                activeLinkClassName="activess"
                                nextLabel=" > "
                                previousLabel=" < "
                                initialPage={pageUpgrade}
                                disableInitialCallback={true}
                                onPageChange={(val) => {
                                  setPageUpgrade(val.selected);
                                }}
                              />
                            </Col>
                          </Row>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </Tab.Pane>
            <Tab.Pane eventKey="account-icons">
              <Container fluid>
                <Row
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: 20,
                  }}
                >
                  <Col>
                    <div style={{ fontSize: 25, fontWeight: "bold" }}>
                      Produk harus PO
                    </div>
                  </Col>
                  <Col style={{ textAlign: "right" }}>
                    {/* <Button
                      style={{ marginLeft: 20, marginBottom: 0 }}
                      onClick={() => {
                        setModal(true);
                      }}
                    >
                      Tambah Produk
                    </Button> */}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        marginBottom: 20,
                      }}
                    >
                      <div>
                        <InputGroup>
                          <Form.Control
                            style={{
                              paddingLeft: "10px",
                              width: "300px",
                            }}
                            type="text"
                            value={searchTextPo}
                            onChange={handleSearchPo}
                            placeholder="Nama Mesin/Kode Mesin"
                          />
                          <InputGroup.Append>
                            <InputGroup.Text
                              style={{ cursor: "pointer" }}
                              onClick={handleClearSearchPo}
                            >
                              <i class="fas fa-times"></i>
                            </InputGroup.Text>
                          </InputGroup.Append>
                        </InputGroup>
                      </div>
                    </div>
                  </Col>
                </Row>
                {/* <Row>
                  <Col>
                    <Button
                      onClick={() => {
                        setModalImport(true);
                      }}
                      className="btn-social"
                      variant="twitter"
                      style={{
                        backgroundColor: "rgba(40,118,74,1)",
                        borderColor: "rgba(40,118,74,1)",
                      }}
                    >
                      <i
                        className="fas fa-file-excel"
                        style={{ marginRight: 10 }}
                      ></i>
                      Import Excel
                    </Button>
                    <Button
                      onClick={() => {
                        window.open(
                          "https://docs.google.com/spreadsheets/d/1Qxfk5Xe0Xbjr39htdHLRoiA6_wSCEWaN/edit?usp=sharing&ouid=116475921970373937456&rtpof=true&sd=true",
                          "_blank"
                        );
                      }}
                      className="btn-social"
                      variant="twitter"
                      style={{
                        backgroundColor: "rgba(64,123,255,1)",
                        borderColor: "rgba(64,123,255,1)",
                        marginLeft: 10,
                      }}
                    >
                      <i
                        className="fas fa-cloud-download-alt"
                        style={{ marginRight: 10 }}
                      ></i>
                      Download Format Import
                    </Button>
                  </Col>
                  <Col>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        marginBottom: 20,
                      }}
                    >
                      <div>
                        <InputGroup>
                          <Form.Control
                            style={{
                              paddingLeft: "10px",
                              width: "300px",
                            }}
                            type="text"
                            value={searchText}
                            onChange={handleSearch}
                            placeholder="Nama Mesin/Kode Mesin"
                          />
                          <InputGroup.Append>
                            <InputGroup.Text
                              style={{ cursor: "pointer" }}
                              onClick={handleClearSearch}
                            >
                              <i class="fas fa-times"></i>
                            </InputGroup.Text>
                          </InputGroup.Append>
                        </InputGroup>
                      </div>
                    </div>
                  </Col>
                </Row> */}

                <Row>
                  <Col md="12">
                    <Card>
                      <Card.Body>
                        <DataTable
                          columns={columnsPo}
                          data={listProdukPo}
                          //   pagination
                          customStyles={customStyles}
                          paginationPerPage={totalPerHalamanPo}
                          //   onChangePage={handlePageChange}
                          progressPending={pending}
                          conditionalRowStyles={conditionalRowStyles}
                        />

                        <div style={{ marginTop: 35 }}>
                          <Row
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Col>
                              <Row style={{ alignItems: "center" }}>
                                <div
                                  style={{
                                    paddingRight: 0,
                                    marginLeft: 20,
                                    marginRight: 10,
                                  }}
                                >
                                  <label
                                    style={{
                                      fontWeight: "bold",
                                      textTransform: "none",
                                      fontSize: 14,
                                    }}
                                  >
                                    Rows per page
                                  </label>
                                </div>
                                <Col sm="3" style={{ paddingLeft: 0 }}>
                                  <Select
                                    className="react-select primary"
                                    classNamePrefix="react-select"
                                    name="Pilih Client"
                                    value={optionsPo.find(
                                      (option) =>
                                        option.value === totalPerHalamanPo
                                    )}
                                    onChange={handlePageSizeChangePo}
                                    options={optionsPo}
                                    placeholder="Rows per page"
                                  />
                                </Col>
                              </Row>
                            </Col>

                            <Col>
                              <ReactPaginate
                                forcePage={pagePo}
                                containerClassName="paginations justify-content-end"
                                previousClassName="pages-items-containers"
                                previousLinkClassName="pages-links-labels"
                                nextClassName="pages-items-containers"
                                nextLinkClassName="pages-links-labels"
                                breakClassName="pages-items-containers"
                                pageCount={produk.listProdukPo.totalPages}
                                pageRangeDisplayed={3}
                                marginPagesDisplayed={1}
                                pageLinkClassName="pages-links-labels"
                                pageClassName="pages-items-containers"
                                activeClassName="actives"
                                activeLinkClassName="activess"
                                nextLabel=" > "
                                previousLabel=" < "
                                initialPage={pagePo}
                                disableInitialCallback={true}
                                onPageChange={(val) => {
                                  setPagePo(val.selected);
                                }}
                              />
                            </Col>
                          </Row>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Container>
              <hr style={{ borderWidth: 5 }}></hr>
              <Container fluid>
                <Row
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: 20,
                  }}
                >
                  <Col>
                    <div style={{ fontSize: 25, fontWeight: "bold" }}>
                      Produk Upgrade harus PO
                    </div>
                  </Col>

                  <Col style={{ textAlign: "right" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        marginBottom: 20,
                      }}
                    >
                      <div>
                        <InputGroup>
                          <Form.Control
                            style={{
                              paddingLeft: "10px",
                              width: "300px",
                            }}
                            type="text"
                            value={searchTextUpgradePo}
                            onChange={handleSearchUpgradePo}
                            placeholder="Nama Mesin/Kode Mesin"
                          />
                          <InputGroup.Append>
                            <InputGroup.Text
                              style={{ cursor: "pointer" }}
                              onClick={handleClearSearchUpgradePo}
                            >
                              <i class="fas fa-times"></i>
                            </InputGroup.Text>
                          </InputGroup.Append>
                        </InputGroup>
                      </div>
                    </div>
                    {/* <Button
              style={{ marginLeft: 20, marginBottom: 0 }}
              onClick={() => {
                setModal(true);
              }}
            >
              Tambah Produk
            </Button> */}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {/* <Button
              onClick={() => {
                setModalImport(true);
              }}
              className="btn-social"
              variant="twitter"
              style={{
                backgroundColor: "rgba(40,118,74,1)",
                borderColor: "rgba(40,118,74,1)",
              }}
            >
              <i className="fas fa-file-excel" style={{ marginRight: 10 }}></i>
              Import Excel
            </Button>
            <Button
              onClick={() => {
                window.open(
                  "https://docs.google.com/spreadsheets/d/1Qxfk5Xe0Xbjr39htdHLRoiA6_wSCEWaN/edit?usp=sharing&ouid=116475921970373937456&rtpof=true&sd=true",
                  "_blank"
                );
              }}
              className="btn-social"
              variant="twitter"
              style={{
                backgroundColor: "rgba(64,123,255,1)",
                borderColor: "rgba(64,123,255,1)",
                marginLeft: 10,
              }}
            >
              <i
                className="fas fa-cloud-download-alt"
                style={{ marginRight: 10 }}
              ></i>
              Download Format Import
            </Button> */}
                  </Col>
                  <Col>
                    {/* <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                marginBottom: 20,
              }}
            >
              <div>
                <InputGroup>
                  <Form.Control
                    style={{
                      paddingLeft: "10px",
                      width: "300px",
                    }}
                    type="text"
                    value={searchTextUpgrade}
                    onChange={handleSearchUpgrade}
                    placeholder="Nama Mesin/Kode Mesin"
                  />
                  <InputGroup.Append>
                    <InputGroup.Text
                      style={{ cursor: "pointer" }}
                      onClick={handleClearSearchUpgrade}
                    >
                      <i class="fas fa-times"></i>
                    </InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </div>
            </div> */}
                  </Col>
                </Row>

                <Row>
                  <Col md="12">
                    <Card>
                      <Card.Body>
                        <DataTable
                          columns={columnsUpgradePo}
                          data={listProdukUpgradePo}
                          //   pagination
                          customStyles={customStyles}
                          paginationPerPage={totalPerHalamanUpgradePo}
                          //   onChangePage={handlePageChange}
                          progressPending={pending}
                          conditionalRowStyles={conditionalRowStyles}
                        />

                        <div style={{ marginTop: 35 }}>
                          <Row
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Col>
                              <Row style={{ alignItems: "center" }}>
                                <div
                                  style={{
                                    paddingRight: 0,
                                    marginLeft: 20,
                                    marginRight: 10,
                                  }}
                                >
                                  <label
                                    style={{
                                      fontWeight: "bold",
                                      textTransform: "none",
                                      fontSize: 14,
                                    }}
                                  >
                                    Rows per page
                                  </label>
                                </div>
                                <Col sm="3" style={{ paddingLeft: 0 }}>
                                  <Select
                                    className="react-select primary"
                                    classNamePrefix="react-select"
                                    name="Pilih Client"
                                    value={optionsUpgradePo.find(
                                      (option) =>
                                        option.value ===
                                        totalPerHalamanUpgradePo
                                    )}
                                    onChange={handlePageSizeChangeUpgradePo}
                                    options={optionsUpgradePo}
                                    placeholder="Rows per page"
                                  />
                                </Col>
                              </Row>
                            </Col>

                            <Col>
                              <ReactPaginate
                                forcePage={pageUpgradePo}
                                containerClassName="paginations justify-content-end"
                                previousClassName="pages-items-containers"
                                previousLinkClassName="pages-links-labels"
                                nextClassName="pages-items-containers"
                                nextLinkClassName="pages-links-labels"
                                breakClassName="pages-items-containers"
                                pageCount={
                                  produk.listProdukUpgradePo.totalPages
                                }
                                pageRangeDisplayed={3}
                                marginPagesDisplayed={1}
                                pageLinkClassName="pages-links-labels"
                                pageClassName="pages-items-containers"
                                activeClassName="actives"
                                activeLinkClassName="activess"
                                nextLabel=" > "
                                previousLabel=" < "
                                initialPage={pageUpgradePo}
                                disableInitialCallback={true}
                                onPageChange={(val) => {
                                  setPageUpgradePo(val.selected);
                                }}
                              />
                            </Col>
                          </Row>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </Container>

      {/* Modal Import Produk */}
      <Modal
        size="md"
        show={modalImport}
        onHide={() => {
          pending ? null : setModalImport(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12" style={{ marginTop: 20 }}>
          <Card className="stacked-form">
            <Card.Header>
              <Card.Title
                as="h4"
                style={{ color: "black", fontWeight: "bold" }}
              >
                Import Produk
                <hr></hr>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col
                  sm="4"
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    alignContent: "center",
                    textAlign: "center",
                  }}
                >
                  {/* <div>Texts</div> */}
                  <label
                    className="label-input-file btn text-white mb-4"
                    htmlFor="import2"
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "#447DF7",
                      border: 0,
                      marginTop: 10,
                    }}
                  >
                    <div
                      style={{
                        color: "#FFFFFF",
                        textTransform: "none",
                      }}
                    >
                      <i
                        className="fas fa-file-excel"
                        style={{ marginRight: 5 }}
                      ></i>
                      Pilih File
                    </div>

                    <input
                      disabled={pending ? true : false}
                      type="file"
                      id="import2"
                      onClick={(e) => {
                        e.target.value = "";
                      }}
                      onChange={handleFileChange}
                      style={{ display: "none" }}
                      accept=".xlsx"
                      multiple
                    />
                  </label>
                </Col>
                <Col
                  style={{
                    alignContent: "center",
                  }}
                >
                  {excel.length === 0 ? (
                    <div>
                      <p style={{ color: "GrayText" }}>File kosong</p>
                    </div>
                  ) : (
                    excel.map((file, index) => (
                      <div className="d-flex align-items-center" key={index}>
                        <p className="mb-1 mr-1">{file.name}</p>

                        <i
                          className="fa fa-times"
                          style={{
                            cursor: "pointer",
                            color: "red",
                            marginLeft: 10,
                          }}
                          onClick={() => (pending ? null : removeExcel(index))}
                        />
                      </div>
                    ))
                  )}
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer style={{ textAlign: "end" }}>
              <Button
                className="btn-fill"
                type="submit"
                disabled={excel.length === 0 || pending ? true : false}
                style={{ backgroundColor: "#00BFFF", border: 0 }}
                onClick={importFile}
              >
                {pending ? "Loading..." : "Submit"}
              </Button>
            </Card.Footer>
          </Card>
        </Col>
      </Modal>

      {/* Modal Tambah Produk */}
      <Modal
        size="md"
        show={modal}
        onHide={() => {
          setModal(false);
          setIdProduk("");
          setNamaProduk("");
          setKapasitasProduk("");
          setTipeProduk("");
          setHargaProduk(0);
          setHargaBeli(0);
          setJumlahStock(0);
          setFotoProduk(null);
          setKodeProduk("");
          setEditFoto(false);
          setKategoriProduk("");
          setMinimalStock(0);
          setReminderStok(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12" style={{ marginTop: 20 }}>
          <Card className="stacked-form">
            <Card.Header>
              <Card.Title
                as="h4"
                style={{ color: "black", fontWeight: "bold" }}
              >
                Form Tambah Produk
                <hr></hr>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Form action="#" method="#">
                <Form.Group>
                  <label style={{ color: "black", fontWeight: "bold" }}>
                    Kode Produk
                  </label>
                  <Form.Control
                    onChange={(e) => {
                      setKodeProduk(e.target.value);
                    }}
                    placeholder="Kode Produk"
                    type="text"
                  ></Form.Control>
                </Form.Group>
                <Form.Group>
                  <label style={{ color: "black", fontWeight: "bold" }}>
                    Nama Produk
                  </label>
                  <Form.Control
                    onChange={(e) => {
                      setNamaProduk(e.target.value);
                    }}
                    placeholder="Nama Produk"
                    type="text"
                  ></Form.Control>
                </Form.Group>
                <Form.Group>
                  <label style={{ color: "black", fontWeight: "bold" }}>
                    Kapasitas Produk
                  </label>
                  <Form.Control
                    onChange={(e) => {
                      setKapasitasProduk(e.target.value);
                    }}
                    placeholder="Kapasitas Produk"
                    type="text"
                  ></Form.Control>
                </Form.Group>
                <Form.Group>
                  <label style={{ color: "black", fontWeight: "bold" }}>
                    Tipe Produk
                  </label>
                  <Form.Control
                    onChange={(e) => {
                      setTipeProduk(e.target.value);
                    }}
                    placeholder="Tipe Produk"
                    type="text"
                  ></Form.Control>
                  {/* <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    name="singleSelect"
                    value={tipeProduk}
                    onChange={(value) => setTipeProduk(value)}
                    options={[
                      { value: "-", label: "Tidak Bertipe" },
                      { value: "Gas", label: "Gas" },
                      { value: "Listrik", label: "Listrik" },
                      { value: "Kayu Bakar", label: "Kayu Bakar" },
                    ]}
                    placeholder="Pilih Tipe Produk"
                  /> */}
                </Form.Group>
                <Form.Group>
                  <label style={{ color: "black", fontWeight: "bold" }}>
                    Kategori Produk
                  </label>

                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    name="singleSelect"
                    value={kategoriProduk}
                    onChange={(value) => setKategoriProduk(value)}
                    options={[
                      { value: "utama", label: "Utama" },
                      { value: "upgrade", label: "Upgrade" },
                    ]}
                    placeholder="Pilih Kategori Produk"
                  />
                </Form.Group>
                <Form.Group>
                  <label style={{ color: "black", fontWeight: "bold" }}>
                    Harga Produk
                  </label>
                  <CurrencyInput
                    className="custom-input"
                    style={{
                      padding: 5,
                      borderRadius: 4,
                      width: "100%",
                      height: "40px",
                      borderWidth: "1px",
                      borderTopColor: "rgba(227, 227, 227, 0.3)",
                      borderLeftColor: "rgba(227, 227, 227, 0.3)",
                      borderBottomColor: "rgba(227, 227, 227, 1)",
                      borderRightColor: "rgba(227, 227, 227, 1)",
                    }}
                    name="nominal"
                    placeholder="Harga Produk"
                    prefix="Rp "
                    onValueChange={(value) => {
                      setHargaProduk(value);
                    }}
                    defaultValue={0}
                  />
                </Form.Group>
                {(auth.responLogin &&
                  auth.responLogin.role === "Super Admin") ||
                (auth.responLogin && auth.responLogin.role === "Management") ? (
                  <Form.Group>
                    <label style={{ color: "black", fontWeight: "bold" }}>
                      Harga Beli Produk
                    </label>
                    <CurrencyInput
                      className="custom-input"
                      style={{
                        padding: 5,
                        borderRadius: 4,
                        width: "100%",
                        height: "40px",
                        borderWidth: "1px",
                        borderTopColor: "rgba(227, 227, 227, 0.3)",
                        borderLeftColor: "rgba(227, 227, 227, 0.3)",
                        borderBottomColor: "rgba(227, 227, 227, 1)",
                        borderRightColor: "rgba(227, 227, 227, 1)",
                      }}
                      name="nominal"
                      placeholder="Harga Beli Produk"
                      prefix="Rp "
                      onValueChange={(value) => {
                        setHargaBeli(value);
                      }}
                      defaultValue={0}
                    />
                  </Form.Group>
                ) : null}

                <Form.Group>
                  <label style={{ color: "black", fontWeight: "bold" }}>
                    Jumlah Stock
                  </label>
                  <Form.Control
                    onChange={(e) => {
                      setJumlahStock(e.target.value);
                    }}
                    placeholder="Jumlah Stock"
                    type="Number"
                    min={0}
                    defaultValue={0}
                  ></Form.Control>
                </Form.Group>
                <Form.Group>
                  <label style={{ color: "black", fontWeight: "bold" }}>
                    Minimal Stock
                  </label>
                  <Form.Control
                    onChange={(e) => {
                      setMinimalStock(e.target.value);
                    }}
                    placeholder="Minimal Stock"
                    type="Number"
                    min={0}
                    defaultValue={0}
                  ></Form.Control>
                </Form.Group>
                <Form.Group>
                  <label style={{ color: "black", fontWeight: "bold" }}>
                    Upload Foto Produk
                  </label>
                  <Form.Control
                    ref={fileInputRef}
                    type="file"
                    accept=".png, .jpg, .jpeg"
                    onChange={(e) => {
                      setFotoProduk(e.target.files[0]);
                      // setSizeFile(e.target.files[0].size / 1000000);
                    }}
                  ></Form.Control>
                  {fotoProduk === null ? null : (
                    <div
                      onClick={() => {
                        setFotoProduk(null);
                        if (fileInputRef.current) {
                          fileInputRef.current.value = null;
                        }
                      }}
                      style={{
                        cursor: "pointer",
                        color: "red",
                        fontSize: 12,
                        marginTop: 10,

                        width: 100,
                      }}
                    >
                      <i className="fas fa-trash"></i>
                      Hapus Foto
                    </div>
                  )}
                </Form.Group>
              </Form>
            </Card.Body>
            <Card.Footer style={{ textAlign: "end" }}>
              <Button
                className="btn-fill"
                type="submit"
                // variant="info"
                style={{ backgroundColor: "#00BFFF", border: 0 }}
                onClick={addProduk}
              >
                Submit
              </Button>
            </Card.Footer>
          </Card>
        </Col>
      </Modal>

      {/* Modal Detail Produk */}
      <Modal
        size="lg"
        show={modalDetail}
        onHide={() => {
          setModalDetail(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12" style={{ marginTop: 20 }}>
          <Card className="stacked-form">
            <Card.Header>
              <Card.Title
                as="h4"
                style={{ color: "black", fontWeight: "bold" }}
              >
                Detail Produk
                <hr></hr>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col>
                  <Row>
                    <Col>
                      <Image
                        src={
                          detailProduk && detailProduk.fotoProduk.length === 0
                            ? require("assets/img/noImage.jpg").default
                            : detailProduk && detailProduk.fotoProduk[0]
                        }
                        style={{
                          objectFit: "contain",
                          width: "300px",
                          height: "300px",
                        }}
                        thumbnail
                      />
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Row>
                    <Col sm="6" style={{ fontWeight: "bold" }}>
                      Kode Produk
                    </Col>
                    <Col sm="0.5">:</Col>
                    <Col sm="5">{detailProduk && detailProduk.kodeProduk}</Col>
                  </Row>
                  <Row>
                    <Col sm="6" style={{ fontWeight: "bold" }}>
                      Nama Produk
                    </Col>
                    <Col sm="0.5">:</Col>
                    <Col sm="5">{detailProduk && detailProduk.namaProduk}</Col>
                  </Row>
                  <Row>
                    <Col sm="6" style={{ fontWeight: "bold" }}>
                      Kapasitas Produk
                    </Col>
                    <Col sm="0.5">:</Col>
                    <Col sm="5">{detailProduk && detailProduk.kapasitas}</Col>
                  </Row>
                  <Row>
                    <Col sm="6" style={{ fontWeight: "bold" }}>
                      Tipe Produk
                    </Col>
                    <Col sm="0.5">:</Col>
                    <Col sm="5">{detailProduk && detailProduk.tipe}</Col>
                  </Row>
                  <Row>
                    <Col sm="6" style={{ fontWeight: "bold" }}>
                      Harga Produk
                    </Col>
                    <Col sm="0.5">:</Col>
                    <Col sm="5">
                      {detailProduk &&
                        new Intl.NumberFormat("id-ID", {
                          style: "currency",
                          currency: "IDR",
                          minimumFractionDigits: 0,
                        }).format(detailProduk.hargaProduk)}
                    </Col>
                  </Row>
                  {(auth.responLogin &&
                    auth.responLogin.role === "Super Admin") ||
                  (auth.responLogin &&
                    auth.responLogin.role === "Management") ? (
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Harga Beli
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">
                        {detailProduk &&
                          new Intl.NumberFormat("id-ID", {
                            style: "currency",
                            currency: "IDR",
                            minimumFractionDigits: 0,
                          }).format(detailProduk.hargaBeli)}
                      </Col>
                    </Row>
                  ) : null}

                  <Row>
                    <Col sm="6" style={{ fontWeight: "bold" }}>
                      Kategori Produk
                    </Col>
                    <Col sm="0.5">:</Col>
                    <Col sm="5">
                      {detailProduk && detailProduk.kategoriProduk}
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="6" style={{ fontWeight: "bold" }}>
                      Stock Gudang
                    </Col>
                    <Col sm="0.5">:</Col>
                    <Col sm="5">
                      {detailProduk && detailProduk.jumlahStock} stock
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="6" style={{ fontWeight: "bold" }}>
                      Stock Sales
                    </Col>
                    <Col sm="0.5">:</Col>
                    <Col sm="5">
                      {detailProduk && detailProduk.stockSales} stock
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="6" style={{ fontWeight: "bold" }}>
                      Minimal Stock
                    </Col>
                    <Col sm="0.5">:</Col>
                    <Col sm="5">
                      {detailProduk && detailProduk.minimalStock} stock
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Modal>

      {/* Modal Edit Produk */}
      <Modal
        size="md"
        show={modalEdit}
        onHide={() => {
          setModalEdit(false);
          setIdProduk("");
          setNamaProduk("");
          setKapasitasProduk("");
          setTipeProduk("");
          setHargaProduk(0);
          setHargaBeli(0);
          setJumlahStock(0);
          setFotoProduk(null);
          setKodeProduk("");
          setEditFoto(false);
          setKategoriProduk("");
          setMinimalStock(0);
          setReminderStok(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12" style={{ marginTop: 20 }}>
          <Card className="stacked-form">
            <Card.Header>
              <Card.Title
                as="h4"
                style={{ color: "black", fontWeight: "bold" }}
              >
                Form Edit Produk
                <hr></hr>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Form action="#" method="#">
                <Form.Group>
                  <label style={{ color: "black", fontWeight: "bold" }}>
                    Kode Produk
                  </label>
                  <Form.Control
                    // disabled
                    onChange={(e) => {
                      setKodeProduk(e.target.value);
                    }}
                    placeholder="Kode Produk"
                    type="text"
                    value={kodeProduk}
                  ></Form.Control>
                </Form.Group>
                <Form.Group>
                  <label style={{ color: "black", fontWeight: "bold" }}>
                    Nama Produk
                  </label>
                  <Form.Control
                    // disabled
                    onChange={(e) => {
                      setNamaProduk(e.target.value);
                    }}
                    placeholder="Nama Produk"
                    type="text"
                    value={namaProduk}
                  ></Form.Control>
                </Form.Group>
                <Form.Group>
                  <label style={{ color: "black", fontWeight: "bold" }}>
                    Kapasitas Produk
                  </label>
                  <Form.Control
                    // disabled
                    onChange={(e) => {
                      setKapasitasProduk(e.target.value);
                    }}
                    placeholder="Kapasitas Produk"
                    type="text"
                    value={kapasitasProduk}
                  ></Form.Control>
                </Form.Group>
                <Form.Group>
                  <label style={{ color: "black", fontWeight: "bold" }}>
                    Tipe Produk
                  </label>
                  <Form.Control
                    // disabled
                    onChange={(e) => {
                      setTipeProduk(e.target.value);
                    }}
                    placeholder="Tipe Produk"
                    type="text"
                    value={tipeProduk}
                  ></Form.Control>
                </Form.Group>
                <Form.Group>
                  <label style={{ color: "black", fontWeight: "bold" }}>
                    Kategori Produk
                  </label>

                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    name="singleSelect"
                    value={kategoriProduk}
                    onChange={(value) => setKategoriProduk(value)}
                    options={[
                      { value: "utama", label: "Utama" },
                      { value: "upgrade", label: "Upgrade" },
                    ]}
                    placeholder="Pilih Kategori Produk"
                  />
                </Form.Group>
                <Form.Group>
                  <label style={{ color: "black", fontWeight: "bold" }}>
                    Reminder Stok
                  </label>

                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    name="singleSelect"
                    value={reminderStok}
                    onChange={(value) => setReminderStok(value)}
                    options={[
                      { value: true, label: "Ya" },
                      { value: false, label: "Tidak" },
                    ]}
                    placeholder="Pilih Remindder Stok"
                  />
                </Form.Group>
                <Form.Group>
                  <label style={{ color: "black", fontWeight: "bold" }}>
                    Harga Produk
                  </label>
                  <CurrencyInput
                    className="custom-input"
                    style={{
                      padding: 5,
                      borderRadius: 4,
                      width: "100%",
                      height: "40px",
                      borderWidth: "1px",
                      borderTopColor: "rgba(227, 227, 227, 0.3)",
                      borderLeftColor: "rgba(227, 227, 227, 0.3)",
                      borderBottomColor: "rgba(227, 227, 227, 1)",
                      borderRightColor: "rgba(227, 227, 227, 1)",
                    }}
                    name="nominal"
                    placeholder="Harga Produk"
                    prefix="Rp "
                    onValueChange={(value) => {
                      setHargaProduk(value);
                    }}
                    defaultValue={hargaProduk}
                  />
                </Form.Group>
                {(auth.responLogin &&
                  auth.responLogin.role === "Super Admin") ||
                (auth.responLogin && auth.responLogin.role === "Management") ? (
                  <Form.Group>
                    <label style={{ color: "black", fontWeight: "bold" }}>
                      Harga Beli Produk
                    </label>
                    <CurrencyInput
                      className="custom-input"
                      style={{
                        padding: 5,
                        borderRadius: 4,
                        width: "100%",
                        height: "40px",
                        borderWidth: "1px",
                        borderTopColor: "rgba(227, 227, 227, 0.3)",
                        borderLeftColor: "rgba(227, 227, 227, 0.3)",
                        borderBottomColor: "rgba(227, 227, 227, 1)",
                        borderRightColor: "rgba(227, 227, 227, 1)",
                      }}
                      name="nominal"
                      placeholder="Harga Beli Produk"
                      prefix="Rp "
                      onValueChange={(value) => {
                        setHargaBeli(value);
                      }}
                      defaultValue={hargaBeli}
                    />
                  </Form.Group>
                ) : null}

                <Form.Group>
                  <label style={{ color: "black", fontWeight: "bold" }}>
                    Tambahan Stock
                  </label>
                  <Form.Control
                    onChange={(e) => {
                      setJumlahStock(e.target.value);
                    }}
                    placeholder="Jumlah Stock"
                    type="Number"
                    min={0}
                    defaultValue={0}
                  ></Form.Control>
                </Form.Group>
                <Form.Group>
                  <label style={{ color: "black", fontWeight: "bold" }}>
                    Minimal Stock
                  </label>
                  <Form.Control
                    onChange={(e) => {
                      setMinimalStock(e.target.value);
                    }}
                    placeholder="Minimal Stock"
                    type="Number"
                    min={0}
                    defaultValue={minimalStock}
                  ></Form.Control>
                </Form.Group>
                <Form.Group>
                  <label style={{ color: "black", fontWeight: "bold" }}>
                    Upload Foto Produk
                  </label>
                  <Form.Control
                    ref={fileInputRef}
                    type="file"
                    accept=".png, .jpg, .jpeg"
                    onChange={(e) => {
                      setFotoProduk(e.target.files[0]);
                      setEditFoto(true);
                      // setSizeFile(e.target.files[0].size / 1000000);
                    }}
                  ></Form.Control>
                  {fotoProduk === null ? null : (
                    <div
                      onClick={() => {
                        setFotoProduk(null);
                        setEditFoto(true);
                        if (fileInputRef.current) {
                          fileInputRef.current.value = null;
                        }
                      }}
                      style={{
                        cursor: "pointer",
                        color: "red",
                        fontSize: 12,
                        marginTop: 10,

                        width: 100,
                      }}
                    >
                      <i className="fas fa-trash"></i>
                      Hapus Foto
                    </div>
                  )}
                </Form.Group>
              </Form>
            </Card.Body>
            <Card.Footer style={{ textAlign: "end" }}>
              <Button
                className="btn-fill"
                type="submit"
                // variant="info"
                style={{ backgroundColor: "#00BFFF", border: 0 }}
                onClick={kirimEditProduk}
              >
                Update
              </Button>
            </Card.Footer>
          </Card>
        </Col>
      </Modal>

      {/* Modal Tambah PO Produk */}
      <Modal
        size="md"
        show={modalPo}
        onHide={() => {
          setModalPo(false);
          setNamaProduk("");
          setKodeProduk("");
          setIdProduk("");
          setJumlahPo(0);
          setHargaBeli(0);
          setTotalHargaPo(0);
          setValidasiJumlahPo(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12" style={{ marginTop: 20 }}>
          <Card className="stacked-form">
            <Card.Header>
              <Card.Title
                as="h4"
                style={{ color: "black", fontWeight: "bold" }}
              >
                Form Tambah PO
                <hr></hr>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Form action="#" method="#">
                <Form.Group>
                  <label style={{ color: "black", fontWeight: "bold" }}>
                    Kode Produk
                  </label>
                  <Form.Control
                    // onChange={(e) => {
                    //   setKodeProduk(e.target.value);
                    // }}
                    placeholder="Kode Produk"
                    disabled
                    type="text"
                    value={kodeProduk}
                  ></Form.Control>
                </Form.Group>
                <Form.Group>
                  <label style={{ color: "black", fontWeight: "bold" }}>
                    Nama Produk
                  </label>
                  <Form.Control
                    // onChange={(e) => {
                    //   setNamaProduk(e.target.value);
                    // }}
                    placeholder="Nama Produk"
                    type="text"
                    disabled
                    value={namaProduk}
                  ></Form.Control>
                </Form.Group>
                <Form.Group>
                  <label style={{ color: "black", fontWeight: "bold" }}>
                    Harga Beli Produk
                  </label>
                  <CurrencyInput
                    disabled
                    className="custom-input"
                    style={{
                      padding: 5,
                      borderRadius: 4,
                      width: "100%",
                      height: "40px",
                      borderWidth: "1px",
                      borderTopColor: "rgba(227, 227, 227, 0.3)",
                      borderLeftColor: "rgba(227, 227, 227, 0.3)",
                      borderBottomColor: "rgba(227, 227, 227, 1)",
                      borderRightColor: "rgba(227, 227, 227, 1)",
                    }}
                    name="nominal"
                    placeholder="Harga Beli Produk"
                    prefix="Rp "
                    onValueChange={(value) => {}}
                    defaultValue={hargaBeli}
                  />
                </Form.Group>
                <Form.Group>
                  <label style={{ color: "black", fontWeight: "bold" }}>
                    Jumlah PO <span style={{ color: "red" }}>*</span>
                  </label>
                  <Form.Control
                    onChange={(e) => {
                      setJumlahPo(e.target.value);
                      setValidasiJumlahPo(false);
                      setTotalHargaPo(e.target.value * hargaBeli);
                    }}
                    placeholder="Jumlah PO"
                    type="Number"
                    min={0}
                    defaultValue={0}
                  ></Form.Control>
                  {validasiJumlahPo ? (
                    <label style={{ color: "red", textTransform: "none" }}>
                      *jumlah PO minimal 1
                    </label>
                  ) : null}
                </Form.Group>
                <Form.Group>
                  <label style={{ color: "black", fontWeight: "bold" }}>
                    Total Harga PO
                  </label>
                  <CurrencyInput
                    disabled
                    className="custom-input"
                    style={{
                      padding: 5,
                      borderRadius: 4,
                      width: "100%",
                      height: "40px",
                      borderWidth: "1px",
                      borderTopColor: "rgba(227, 227, 227, 0.3)",
                      borderLeftColor: "rgba(227, 227, 227, 0.3)",
                      borderBottomColor: "rgba(227, 227, 227, 1)",
                      borderRightColor: "rgba(227, 227, 227, 1)",
                    }}
                    name="nominal"
                    placeholder="Harga Beli Produk"
                    prefix="Rp "
                    onValueChange={(value) => {}}
                    value={totalHargaPo}
                  />
                </Form.Group>
              </Form>
            </Card.Body>
            <Card.Footer style={{ textAlign: "end" }}>
              <Button
                className="btn-fill"
                type="submit"
                // variant="info"
                style={{ backgroundColor: "#00BFFF", border: 0 }}
                onClick={addPo}
              >
                Submit
              </Button>
            </Card.Footer>
          </Card>
        </Col>
      </Modal>
    </>
  );
}

export default Produk;
